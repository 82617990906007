import React, { Component } from 'react'
import { Row, Col, Layout, Menu, Icon, List, Card, Anchor, Avatar } from 'antd';
import { Link } from "gatsby";
import SupportLayout from '../../components/support';
import SEO from '../../components/seo';
import { isMobile } from "react-device-detect";
import { CreditCardOutlined, BarsOutlined, TeamOutlined, ApartmentOutlined, UserOutlined } from '@ant-design/icons';

const data = [
    {title: 'For Investors',icon: 'apartment', link:'/support/help/investors/',},
    {title: 'For Landlords',icon: 'team', link:'/support/help/landlords/',},
    {title: 'For Residents',icon: 'user', link:'/support/help/residents/',},
    {title: 'Payments',icon: 'credit-card', link:'/support/help/payments/',},
    {title: 'FAQs',icon: 'bars', link:'/support/faq/',},
  ];

const GettingStarted = (props) => {
    return (
        <SupportLayout  {...props}>
        <SEO title="Getting Started" description='Getting Started walkthrogh of RealtyStacks software' />
        <Row style={{margin:'20px' }}>
            <Col sm={22} md={20} style={{marginBottom:'50px'}}>
            <h2 style={{fontSize:'28px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'nowrap'}}>
            Getting <span style={{fontWeight:600}}>Started</span>
            </h2>
            <p style={{fontSize:'20px', lineHeight:'1.2', color:'#495057'}}>
                Welcome to the RealtyStack help center. Get familiar with our software
            </p>
            <Row>
            {data.map(item => (
                <Col sm={24} md={12} lg={8}>
                <Link to={item.link}>
                    <Card
                    className='help-card-list'
                    hoverable
                    >
                        <Card.Meta
                        title={item.title}
                        avatar={<Avatar style={{verticalAlign:'center', color:'#fff', borderImage:'linear-gradient(-303deg,#00a4bd,#00afb2 56%,#00bda5)', background:'linear-gradient(-303deg,#00a4bd,#00afb2 56%,#00bda5)'}} />}
                        />
                    </Card>
                </Link>
                </Col>
            ))}
            </Row>
            <p style={{marginTop:'30px'}}>
                RealtyStack software provides all of the tools and features for managing a real estate portfolio and its residents. With Realtystack you can 
                collect rent, security deposits, and other one time payments from your residents as well as track and manage all of your realestate data on
                our simple to use platform.
            </p>
            <h2 id='intro' style={{fontSize:'22px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'nowrap'}}>
             <span style={{fontWeight:600}}>Intro</span>
            </h2>
            <p>Cillum cillum cupidatat amet culpa est esse consequat laboris amet. </p>
            <p>
                Sit labore consectetur fugiat anim quis. Elit minim eu velit ea tempor commodo sit sint fugiat irure velit ut. Laborum nulla consectetur quis 
                adipisicing occaecat eu commodo officia sunt reprehenderit ea et do cupidatat. Incididunt culpa est magna enim voluptate. Pariatur ut ea excepteur
                nisi laborum qui consequat. Cillum minim nostrud consequat veniam excepteur ad.
            </p>
            <h2 id='setup' style={{fontSize:'22px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'nowrap'}}>
            <span style={{fontWeight:600}}>Setup</span>
            </h2>
            <p>
                Sit labore consectetur fugiat anim quis. Elit minim eu velit ea tempor commodo sit sint fugiat irure velit ut. Laborum nulla consectetur quis 
                adipisicing occaecat eu commodo officia sunt reprehenderit ea et do cupidatat. Incididunt culpa est magna enim voluptate. Pariatur ut ea excepteur
                nisi laborum qui consequat. Cillum minim nostrud consequat veniam excepteur ad.
            </p>
            <p>
                Sit labore consectetur fugiat anim quis. Elit minim eu velit ea tempor commodo sit sint fugiat irure velit ut. Laborum nulla consectetur quis 
                adipisicing occaecat eu commodo officia sunt reprehenderit ea et do cupidatat. Incididunt culpa est magna enim voluptate. Pariatur ut ea excepteur
                nisi laborum qui consequat. Cillum minim nostrud consequat veniam excepteur ad.
            </p>
            <h2 id='investors' style={{fontSize:'22px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'nowrap'}}>
            <span style={{fontWeight:600}}>Investors</span>
            </h2>
            <p>
                Sit labore consectetur fugiat anim quis. Elit minim eu velit ea tempor commodo sit sint fugiat irure velit ut. Laborum nulla consectetur quis 
                adipisicing occaecat eu commodo officia sunt reprehenderit ea et do cupidatat. Incididunt culpa est magna enim voluptate. Pariatur ut ea excepteur
                nisi laborum qui consequat. Cillum minim nostrud consequat veniam excepteur ad.
            </p>
            <h2 id='properties' style={{fontSize:'22px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'nowrap'}}>
            <span style={{fontWeight:600}}>Properties</span>
            </h2>
            <p>
                Sit labore consectetur fugiat anim quis. Elit minim eu velit ea tempor commodo sit sint fugiat irure velit ut. Laborum nulla consectetur quis 
                adipisicing occaecat eu commodo officia sunt reprehenderit ea et do cupidatat. Incididunt culpa est magna enim voluptate. Pariatur ut ea excepteur
                nisi laborum qui consequat. Cillum minim nostrud consequat veniam excepteur ad.
            </p>
            <h2 id='units' style={{fontSize:'22px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'nowrap'}}>
            <span style={{fontWeight:600}}>Units</span>
            </h2>
            <p>
                Sit labore consectetur fugiat anim quis. Elit minim eu velit ea tempor commodo sit sint fugiat irure velit ut. Laborum nulla consectetur quis 
                adipisicing occaecat eu commodo officia sunt reprehenderit ea et do cupidatat. Incididunt culpa est magna enim voluptate. Pariatur ut ea excepteur
                nisi laborum qui consequat. Cillum minim nostrud consequat veniam excepteur ad.
            </p>
            <h2 id='residents' style={{fontSize:'22px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'nowrap'}}>
            <span style={{fontWeight:600}}>Residents</span>
            </h2>
            <p>
                Sit labore consectetur fugiat anim quis. Elit minim eu velit ea tempor commodo sit sint fugiat irure velit ut. Laborum nulla consectetur quis 
                adipisicing occaecat eu commodo officia sunt reprehenderit ea et do cupidatat. Incididunt culpa est magna enim voluptate. Pariatur ut ea excepteur
                nisi laborum qui consequat. Cillum minim nostrud consequat veniam excepteur ad.
            </p>
            <p>
                Sit labore consectetur fugiat anim quis. Elit minim eu velit ea tempor commodo sit sint fugiat irure velit ut. Laborum nulla consectetur quis 
                adipisicing occaecat eu commodo officia sunt reprehenderit ea et do cupidatat. Incididunt culpa est magna enim voluptate. Pariatur ut ea excepteur
                nisi laborum qui consequat. Cillum minim nostrud consequat veniam excepteur ad.
            </p>
        </Col>
        <Col sm={0} md={4}>
            {isMobile ? null :
            <Anchor offsetTop={120} style={{float:'right'}}>
                <Anchor.Link href="#home" title="Home" />
                <Anchor.Link href="#getting-started" title="Getting Started" >
                <Anchor.Link href="#intro" title="Intro" />
                <Anchor.Link href="#setup" title="Setup" />
                </Anchor.Link>
                <Anchor.Link href="#investors" title="Investors" />
                <Anchor.Link href="#properties" title="Properties" >
                <Anchor.Link href="#units" title="Units" />
                <Anchor.Link href="#residents" title="Residents" />
                <Anchor.Link href="#setup" title="Setup" />
                </Anchor.Link>
            </Anchor>}
        </Col>
    </Row>
    </SupportLayout>
    )
}

export default GettingStarted;